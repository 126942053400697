<template>
  <q-card>
    <q-card-section v-if="rawData.options && rawData.options.title" class="text-h6">
      {{ rawData.options.title }}
    </q-card-section>

    <q-card-section class="text-center">
      <div
        v-for="(item, i) in data"
        :key="`${item.event}:${i}`"
        class="q-mb-sm"
      >
        <option-object
          :data="item"
          @click="handleClick"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
// Components
import OptionObject from './option-object.vue'

export default {
  name: 'OptionsCollection',
  components: {
    OptionObject
  },
  emits: ['click'],
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleClick (data) {
      this.$emit('click', data)
    }
  }
}
</script>
