<template>
  <q-btn
    :label="data.label"
    :color="data.color || 'dark'"
    no-caps
    @click="handleClick"
  />
</template>

<script>
export default {
  name: 'Option',
  emits: ['click'],
  props: {
    data: {
      type: Object,
      default () {
        return null
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('click', { event: this.data.event })
    }
  }
}
</script>
